import React, {
  createContext, useState, ReactElement, useEffect,
} from 'react';

import { Slug } from './types';

import { INITIAL_FORM_DATA, OnboardingFormData } from './defaultFormData';
import { defaultCaregiverFlow } from './userFlows';

type FlowStep = {
    element: ReactElement,
    breadCrumb: string,
};

type EligibilityFormCustomization = {
  shouldOmitEmployeeId?: boolean,
}

export type OnboardingFlowContextValue = {
    currentStepIndex: number,
    currentStep: FlowStep,
    steps: FlowStep[],
    back: () => void,
    next: () => void,
    onboardingFormData: OnboardingFormData,
    eligibilityFormCustomization: EligibilityFormCustomization,
    productId: number,
    updateFormData: (arg0: Partial<OnboardingFormData>) => void,
    resetFormData: () => void;
    setSteps: (arg0: FlowStep[]) => void,
    setSlug: (arg0: Slug) => void,
    setProductId: (id: number) => void,
    uid: string,
    setUid: (uid: string) => void,
}

const initalOnboardingFlowContext: OnboardingFlowContextValue = {
  currentStepIndex: undefined,
  currentStep: undefined,
  steps: undefined,
  back: undefined,
  next: undefined,
  onboardingFormData: undefined,
  eligibilityFormCustomization: {},
  productId: undefined,
  updateFormData: undefined,
  resetFormData: undefined,
  setSteps: undefined,
  setSlug: undefined,
  setProductId: undefined,
  uid: undefined,
  setUid: undefined,
};

export const OnboardingFlowContext = createContext(initalOnboardingFlowContext);

export const OnboardingFlowProvider = (props: {children: React.ReactElement}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [onboardingFormData, setOnboardingFormData] = useState(INITIAL_FORM_DATA);
  const [eligibilityFormCustomization, setEligibilityFormCustomization] = useState<EligibilityFormCustomization>({});
  const [productId, setProductId] = useState<number>(4);
  const [steps, setSteps] = useState([]);
  const [slug, setSlug] = useState<Slug>();
  const [uid, setUid] = useState<string>();

  const updateFormData = (newData: Partial<OnboardingFormData>) => {
    setOnboardingFormData((currData) => ({ ...currData, ...newData }));
  };

  // TODO: move useEffect logic to it's own file so this doesn't get bloated with new customers
  useEffect(() => {
    switch (slug) {
    case 'testsparkfilebasedorg':
      setSteps(defaultCaregiverFlow);
      setOnboardingFormData({ ...INITIAL_FORM_DATA, slug });
      setProductId(4);
      break;
    case 'rtx':
      setSteps(defaultCaregiverFlow);
      setOnboardingFormData({ ...INITIAL_FORM_DATA, slug });
      setEligibilityFormCustomization({ shouldOmitEmployeeId: true });
      setProductId(4);
      break;
    default:
      setSteps([]);
    }
  }, [slug]);

  const resetFormData = () => {
    setOnboardingFormData({ ...INITIAL_FORM_DATA, slug });
  };

  const next = () => {
    setCurrentStepIndex((i) => (i >= steps.length - 1 ? i : i + 1));
  };

  const back = () => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  };

  return (
    <OnboardingFlowContext.Provider
      value={{
        back,
        next,
        currentStepIndex,
        currentStep: steps[currentStepIndex] || undefined,
        steps,
        onboardingFormData,
        eligibilityFormCustomization,
        updateFormData,
        resetFormData,
        setSteps,
        setSlug,
        productId,
        setProductId,
        uid,
        setUid,
      }}
    >
      {props.children}
    </OnboardingFlowContext.Provider>
  );
};
