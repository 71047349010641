import React from 'react';

import {
  LoadingSpinner, Table, Row, Text, Link, Column, SortState,
} from 'react-limbix-ui';
import { Box } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import Styled from './Home.styled';

import { getErrorStringKey } from '@/utils/errorUtils';
import { useQuery } from '@/hooks/apollo';

import {
  AssignmentType,
  AssignmentTypeOfClient,
} from '@/apollo/types';

import { ASSIGNMENTS_QUERY } from '@/apollo/queries';

type DisplayAssignment = {
  key?: string;
  name?: string;
  forUser?: string;
  assessmentUid: string;
  assignmentUid: string;
  status: string;
  disabled: boolean;
};

const STATUS_COLUMN_ID = 'status';
const NAME_COLUMN_ID = 'name';
const SORT_STATE: SortState = {
  columnId: STATUS_COLUMN_ID,
  direction: 'asc',
};

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(ASSIGNMENTS_QUERY, {
    variables: {
      onlyVisible: true,
      clientType: AssignmentTypeOfClient.WebPortal,
    },
  });

  if (loading) {
    return (
      <Box padding="24px">
        <LoadingSpinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        {t(getErrorStringKey([error]))}
      </Box>
    );
  }

  const getDisplayAssignments = (assignments: AssignmentType[]): DisplayAssignment[] => {
    if (!assignments) {
      return [];
    }
    return assignments.map((assignment:AssignmentType): DisplayAssignment => {
      if (assignment) {
        const name = assignment.assessment?.name;
        const assessmentUid = assignment.assessment?.uid;
        const assignmentUid = assignment.uid;
        const status = assignment.completeTime ? 'complete' : 'start';
        return {
          key: assignment.uid,
          name: name || '',
          forUser: assignment.aboutUser?.firstName || '',
          assessmentUid,
          assignmentUid,
          status,
          disabled: status === 'complete',
        };
      }
      return {
        name: t('home.invalid'),
        assessmentUid: '',
        assignmentUid: '',
        status: 'invalid',
        disabled: true,
      };
    });
  };

  const getRowsFromDisplayAssignments = (
    displayAssignments: DisplayAssignment[],
  ): Row[] => displayAssignments.map((assignment) => {
    const cells = [
      {
        columnId: NAME_COLUMN_ID,
        value: 'string',
        render: (
          <Box>
            <Text fontWeight="bold" fontSize="medium" color={assignment.disabled ? '#9b9ca0' : undefined}>
              {assignment.name}
            </Text>
            {
              assignment.forUser && (
                <Box>
                  <Text color={assignment.disabled ? '#9b9ca0' : undefined} fontSize="small">
                    {t('home.for')}
                    {' '}
                    {assignment.forUser}
                  </Text>
                </Box>
              )
            }
          </Box>
        ),
      },
      {
        columnId: STATUS_COLUMN_ID,
        value: 'string',
        render: (
          assignment.status === 'complete'
            ? (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Styled.CircleIcon />
                <Text color={assignment.disabled ? '#9b9ca0' : undefined} fontSize="small">
                  {t('home.completed')}
                </Text>
              </Box>
            ) : (
              <Link.RouterLink
                to={{
                  pathname: `/${assignment.assignmentUid}`,
                  state: { assessmentUid: `${assignment.assessmentUid}` },
                }}
              >
                { t('home.start') }
              </Link.RouterLink>
            )
        ),
      },
    ];
    return {
      id: assignment.key,
      cells,
      disabled: assignment.disabled,
      hover: false,
    };
  });

  const getColumns = () => {
    const columns: Column[] = [{
      id: NAME_COLUMN_ID,
      text: t('home.name'),
      valueType: 'string',
    }];

    columns.push({
      id: STATUS_COLUMN_ID,
      text: t('home.status'),
      valueType: 'string',
    });

    return columns;
  };

  const displayAssignments = getDisplayAssignments(data.assignments);
  const rows = getRowsFromDisplayAssignments(displayAssignments);

  return (
    <Box display="block" marginTop="24px">
      <Text as="h2" fontWeight="bold" lineHeight="2">
        {t('home.my_assessments')}
      </Text>
      <Table
        ariaLabel="assignments-table"
        onSortByColumn={() => {}}
        sortState={SORT_STATE}
        columns={getColumns()}
        rows={rows}
      />
    </Box>
  );
};

export default Home;
