import { Box } from 'react-limbix-ui';
import styled from 'styled-components';

const Title = styled(Box)`
    font-family: "GT Walsheim";
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    margin-bottom: 8px;
    @media only screen and (max-width: 600px) {
        font-size: 24px;
    }
`;

const Subtitle = styled(Box)`
    font-family: "GT Walsheim";
    font-size: 18px;
    line-height: 26.6px;
    font-weight: 400;
    margin-bottom: 32px;
    @media only screen and (max-width: 600px) {
        font-size: 18px;
    }
`;
export default {
  Title,
  Subtitle,
};
