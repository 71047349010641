import { OnboardingFormData } from '../../defaultFormData';

import { validateEmail, validatePhoneNumber } from '@/utils/stringValidators';

const validatePatientInfoForm = (onboardingFormData: Partial<OnboardingFormData>): boolean => {
  if (onboardingFormData.personEnrolling === 'patient') {
    return validateEmail(onboardingFormData.patientEmail)
            && validatePhoneNumber(onboardingFormData.patientPhoneNumber)
            && onboardingFormData.patientStreetAddress !== ''
            && onboardingFormData.patientCity !== ''
            && onboardingFormData.patientState !== ''
            && onboardingFormData.safetyAcknowledgement === true
            && onboardingFormData.policyAndTermsAcknowledgement === true;
  }
  return validateEmail(onboardingFormData.patientEmail)
            && validatePhoneNumber(onboardingFormData.patientPhoneNumber)
            && onboardingFormData.patientStreetAddress !== ''
            && onboardingFormData.patientCity !== ''
            && onboardingFormData.patientState !== '';
};

export default validatePatientInfoForm;
