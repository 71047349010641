import React, { CSSProperties } from 'react';
import {
  Link, Text,
} from 'react-limbix-ui';
import { useTranslation } from 'react-i18next';

import Styled from './KaiserSignupPage.styles';

import SignupSuccessGraphic from '@/assets/SignupSuccessGraphic.svg';

type Props = {
  email: string;
  style?: CSSProperties;
};

export default ({ email, style }: Props) => {
  const { t } = useTranslation();
  return (
    <Styled.MainContainer style={style}>
      <Styled.MainContentContainer>
        <Styled.AccountCreatedGraphic src={SignupSuccessGraphic} alt="signup-success-graphic" />
        <Text textAlign="center" fontSize="32px" fontWeight={700} marginBottom="16px">
          {t('kaiser_signup.sparkrx_access_has_been_renewed', { email })}
        </Text>
        <Text fontSize="20px" textAlign="center">
          {t('kaiser_signup.need_to_download_sparkrx_again')}
          {' '}
          <Link.ButtonLink
            onClick={() => window.open(
              'https://www.sparkrx.com/kaiser-welcome',
              '_blank',
              'noopener',
            )}
            fontSize="20px"
          >
            {t('kaiser_signup.click_here')}
          </Link.ButtonLink>
          .
        </Text>
      </Styled.MainContentContainer>
    </Styled.MainContainer>
  );
};
