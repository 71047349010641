import React, { useContext } from 'react';
import { Box } from 'react-limbix-ui';

import { Titles } from '../../components/Titles/Titles';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';

import LimbotHappy from '@/assets/LimbotHappy.svg';

export const TextMessageSent: React.FC = () => {
  const { onboardingFormData } = useContext(OnboardingFlowContext);

  if (onboardingFormData.personEnrolling === 'caregiver') {
    return (
      <>
        <Titles
          title="Time to download the app!"
          subtitle={
            `We sent your dependent an email and
             text with download link and activation code.`
          }
        />
        <Box
          width="100%"
          textAlign="center"
        >
          <img
            alt="Limbot being happy"
            style={{ width: '206px' }}
            src={LimbotHappy}
          />
        </Box>
      </>
    );
  }
  return (
    <>
      <Titles
        title="Time to download the app!"
        subtitle="We sent you an email and text with download link and activation code."
      />
      <Box
        width="100%"
        textAlign="center"
      >
        <img
          alt="Limbot being happy"
          style={{ width: '206px' }}
          src={LimbotHappy}
        />
      </Box>
    </>
  );
};
