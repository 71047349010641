import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { Container } from '@material-ui/core';
import {
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { Box } from 'react-limbix-ui';

import { Header, Modal } from './components';
import IdleTimer from './components/IdleTimer/IdleTimer';
import { DEFAULT_LANGUAGE } from './utils/translate';
import Routes from './routes';
import { useModal } from './hooks/redux';

const IDLE_DURATION_MS = 480000;
const COUNTDOWN_DURATION_MS = 120000;

const App: React.FC<RouteComponentProps> = ({ location }: RouteComponentProps) => {
  const client = useApolloClient();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const firstName = localStorage.getItem('firstName');
  const { showModal } = useModal();
  const { t, i18n } = useTranslation();

  // handle local storage being cleared from somewhere else in the app
  if (isLoggedIn && !localStorage.getItem('token')) {
    setIsLoggedIn(false);
  }

  if (!isLoggedIn) {
    localStorage.clear();
    client.cache.reset();
    return <Redirect to="/login" />;
  }

  if (isLoggedIn && i18n.language !== (localStorage.getItem('language') || DEFAULT_LANGUAGE)) {
    i18n.changeLanguage(localStorage.getItem('language') || DEFAULT_LANGUAGE);
  }

  const handleLogout = () => {
    if (location.pathname !== '/') {
      showModal(
        'GENERIC_WARNING',
        {
          headerText: t('app.logout.sure'),
          primaryButtonText: t('app.logout.return'),
          secondaryButtonText: t('app.logout.logout'),
          onClickSecondaryButton: () => {
            setIsLoggedIn(false);
          },
        },
      );
    } else {
      setIsLoggedIn(false);
    }
  };

  return (
    <Container>
      <Header
        firstName={firstName}
        onClickLogout={handleLogout}
      />
      <Box marginTop="75px">
        <Routes />
      </Box>
      <IdleTimer
        idleDuration={IDLE_DURATION_MS}
        countdownDuration={COUNTDOWN_DURATION_MS}
        onLogout={() => { setIsLoggedIn(false); }}
      />
      <Modal />
    </Container>
  );
};

export default App;
