export const stepContent = (personEnrolling: 'patient' | 'caregiver') => {
  if (personEnrolling === 'caregiver') {
    return {
      title: 'First, let’s confirm if your dependent is covered',
      subtitle: 'Check if Spark Direct is covered by your insurance or employer.',
      formTitle: 'Dependent’s info',
      firstNamePlaceholder: "Dependent's first name",
      firstNameLabel: "Dependent's first name",
      lastNamePlaceholder: "Dependent's last name",
      lastNameLabel: "Dependent's last name",
      zipCodePlaceholder: "Dependent's zip code",
      zipCodeLabel: "Dependent's zip code",
      dobPlaceholder: "Dependent's date of birth",
      dobLabel: "Dependent's date of birth",
      employeeIdPlaceholder: 'Your Employee ID',
      employeeIdLabel: 'Your Employee ID',
    };
  }
  return {
    title: 'First, let’s confirm if you\'re covered',
    subtitle: 'Check if Spark Direct is covered by your insurance or employer.',
    formTitle: 'Your info',
    firstNamePlaceholder: 'First name',
    firstNameLabel: 'First name',
    lastNamePlaceholder: 'Last name',
    lastNameLabel: 'Last name',
    zipCodePlaceholder: 'Zip code',
    zipCodeLabel: 'Zip code',
    dobPlaceholder: 'Date of birth',
    dobLabel: 'Date of birth',
    employeeIdPlaceholder: 'Employee ID',
    employeeIdLabel: 'Employee ID',
  };
};
