import styled from 'styled-components/macro';
import { Box } from 'react-limbix-ui';

const BreadCrumbsContainer = styled(Box)`
    display: flex;
    margin: auto;
`;

const BreadCrumbContainer = styled(Box)`
    display: flex;
    align-items: center;
`;

const LargeDash = styled(Box)`
    margin-right: 4px;
    margin-left: 4px;
    font-size: 28px;
    font-weight: 500;
    line-height: 20px;
`;

const ActiveBreadCrumbIcon = styled.div`
    font-family: "GT Walsheim";
    width: 26px;
    height: 26px;
    background-color: #363334;
    color: #F7F6F5;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border-radius: 50%;
    margin-right: 3px;
    margin-left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
`;

const IncompleteBreadCrumbIcon = styled(Box)`
    font-family: "GT Walsheim";
    width: 26px;
    height: 26px;
    border: 1.5px solid #363334;
    background-color: #F7F6F5;
    color: #363334;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border-radius: 50%;
    margin-right: 3px;
    margin-left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
`;

const BreadCrumbCompleteIcon = styled.img`
    width: 26px;
    margin-right: 3px;
    margin-left: 3px;
`;

const BreadCrumbText = styled(Box)`
    font-family: "GT Walsheim";
    color: #363334;
    font-size: 18px;
    font-weight: 500;
    margin-right: 4px;
    margin-left: 4px;
`;

export default {
  BreadCrumbsContainer,
  BreadCrumbContainer,
  LargeDash,
  ActiveBreadCrumbIcon,
  IncompleteBreadCrumbIcon,
  BreadCrumbCompleteIcon,
  BreadCrumbText,
};
