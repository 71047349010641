import React, { useContext, useState } from 'react';
import { Text, Box } from 'react-limbix-ui';
import { TextField } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';

import { CTAButton } from '../../components/CTAButton/CTAButton';
import { DataSharingInfo } from '../../components/DataSharingInfo/DataSharingInfo';
import { Titles } from '../../components/Titles/Titles';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';
import CoverageRejection from '../CoverageRejection';
import { stepContent } from './stepContent';

import { OverrideToggle } from '../../components/OverrideToggle/OverrideToggle';

import {
  getAge, validateDOB, validateZipCode,
} from '@/utils/stringValidators';
import { CHECK_ELIGIBILITY_QUERY } from '@/apollo/queries';
import { SPARKRX_PDT_UID } from '@/utils/constants';
import { convertDOBToDateString } from '@/utils/stringUtils';

export const CheckCoverage: React.FC = () => {
  const [overrideEligibilityCheck, setOverrideEligibilityCheck] = useState(false);
  const [returnIneligibleMessage, setReturnIneligibleMessage] = useState(false);

  const {
    next,
    updateFormData,
    onboardingFormData,
    eligibilityFormCustomization: { shouldOmitEmployeeId },
    uid,
    productId,
  } = useContext(OnboardingFlowContext);
  const [checkEligibility, { loading }] = useLazyQuery(CHECK_ELIGIBILITY_QUERY);
  const [isEligible, setIsEligible] = useState(undefined);
  const {
    title,
    subtitle,
    formTitle,
    firstNamePlaceholder,
    lastNamePlaceholder,
    zipCodePlaceholder,
    dobPlaceholder,
    firstNameLabel,
    lastNameLabel,
    zipCodeLabel,
    dobLabel,
    employeeIdPlaceholder,
    employeeIdLabel,
  } = stepContent(onboardingFormData.personEnrolling);

  // validate user input
  const formValidated = onboardingFormData.patientFirstName !== ''
        && onboardingFormData.patientLastName !== ''
        && validateDOB(onboardingFormData.patientDateOfBirth)
        && (onboardingFormData.employeeId !== '' || shouldOmitEmployeeId)
        && validateZipCode(onboardingFormData.patientZipCode);

  // validate each text field individually. used for error states and helper text...
  const [patientFirstNameFieldError, setPatientFirstNameFieldError] = useState(false);
  const [patientLastNameFieldError, setPatientLastNameFieldError] = useState(false);
  const [patientZipcodeFieldError, setPatientZipcodeFieldError] = useState(false);
  const [patientEmployeeIdFieldError, setPatientEmployeeIdFieldError] = useState(false);
  const [patientDOBFieldError, setPatientDOBFieldError] = useState(false);
  const [patientDOBFieldErrorMessage, setPatientDOBFieldErrorMessage] = useState('');

  const isCaregiver = onboardingFormData.personEnrolling === 'caregiver';

  const checkCoverage = async () => {
    if (returnIneligibleMessage) {
      setIsEligible(false);
      return;
    }
    if (overrideEligibilityCheck) {
      next();
      return;
    }
    try {
      const result = await checkEligibility({
        variables: {
          pdtUid: SPARKRX_PDT_UID,
          firstName: onboardingFormData.patientFirstName,
          lastName: onboardingFormData.patientLastName,
          dateOfBirth: convertDOBToDateString(onboardingFormData.patientDateOfBirth),
          postalCode: onboardingFormData.patientZipCode,
          organizationSlug: onboardingFormData.slug,
          employeeId: shouldOmitEmployeeId ? null : onboardingFormData.employeeId,
          uid,
          productId,
        },
      });

      if (result?.data?.eligibilityStatus.isEligible) {
        // happy path, eligible
        next();
      } else if (result?.data?.eligibilityStatus.isEligible === false) {
        // happy path, not eligible
        console.warn(result.error);
        setIsEligible(false);
      } else {
        console.warn('we cannot determine whether the user is eligible.');
        alert(result.error);
        // we can't determine whether they are eligible. Maybe they put in the wrong data to check.
        // TODO: we can drill into error messages but what do we want to show to the user?
      }
    } catch (e) {
      // there was a server or browser error
      // TODO: check with design/product on what to show the user here. Maybe "Try Again"?
      console.warn('This was a browser or server error.');
      alert(e);
    }
  };

  if (isEligible === false) {
    return <CoverageRejection isCaregiver={isCaregiver} />;
  }
  return (
    <>
      <Titles
        title={title}
        subtitle={subtitle}
      />
      <Titles
        title={formTitle}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientFirstNameFieldError}
        helperText={patientFirstNameFieldError ? 'Please enter a first name' : ''}
        label={firstNameLabel}
        id="coverageFormFirstName"
        inputProps={{
          placeholder: firstNamePlaceholder,
        }}
        value={onboardingFormData.patientFirstName}
        onChange={(e) => {
          setPatientFirstNameFieldError(false);
          updateFormData({ patientFirstName: e.target.value });
        }}
        onBlur={(e) => {
          setPatientFirstNameFieldError(e.target.value === '');
        }}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientLastNameFieldError}
        helperText={patientLastNameFieldError ? 'Please enter a last name' : ''}
        label={lastNameLabel}
        id="coverageFormLastName"
        inputProps={{
          placeholder: lastNamePlaceholder,
        }}
        value={onboardingFormData.patientLastName}
        onChange={(e) => {
          setPatientLastNameFieldError(false);
          updateFormData({ patientLastName: e.target.value });
        }}
        onBlur={(e) => {
          setPatientLastNameFieldError(e.target.value === '');
        }}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientZipcodeFieldError}
        helperText={patientZipcodeFieldError ? 'Please enter a zipcode' : ''}
        label={zipCodeLabel}
        id="coverageFormZipCode"
        inputProps={{
          placeholder: zipCodePlaceholder,
        }}
        value={onboardingFormData.patientZipCode}
        onChange={(e) => {
          setPatientZipcodeFieldError(false);
          updateFormData({ patientZipCode: e.target.value });
        }}
        onBlur={(e) => {
          setPatientZipcodeFieldError(!validateZipCode(e.target.value));
        }}
      />
      { shouldOmitEmployeeId ? null : (
        <TextField
          className="input-field"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          error={patientEmployeeIdFieldError}
          helperText={patientEmployeeIdFieldError ? 'Please enter an employee ID' : ''}
          label={employeeIdLabel}
          id="coverageFormEmployeeId"
          inputProps={{
            placeholder: employeeIdPlaceholder,
          }}
          value={onboardingFormData.employeeId}
          onChange={(e) => {
            setPatientEmployeeIdFieldError(false);
            updateFormData({ employeeId: e.target.value });
          }}
          onBlur={(e) => {
            setPatientEmployeeIdFieldError(e.target.value === '');
          }}
        />
      )}
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientDOBFieldError}
        helperText={patientDOBFieldErrorMessage}
        label={dobLabel}
        id="coverageFormDOB"
        inputProps={{
          placeholder: dobPlaceholder,
        }}
        value={onboardingFormData.patientDateOfBirth}
        onChange={(e) => {
          setPatientDOBFieldError(false);
          setPatientDOBFieldErrorMessage('');
          updateFormData({ patientDateOfBirth: e.target.value });
          if (validateDOB(e.target.value)) {
            if (getAge(e.target.value) < 13) {
              setPatientDOBFieldErrorMessage(
                `Spark Direct is not intended for 
                users under the age of 13. If your 
                dependent is under the age of 13, 
                they are not eligible to use Spark Direct.`,
              );
              setPatientDOBFieldError(true);
            }
          }
        }}
        onBlur={(e) => {
          if (validateDOB(e.target.value)) {
            if (getAge(e.target.value) < 13) {
              setPatientDOBFieldErrorMessage(
                `Spark Direct is not intended for 
                users under the age of 13. If your 
                dependent is under the age of 13, 
                they are not eligible to use Spark Direct.`,
              );
              setPatientDOBFieldError(true);
            }
          } else {
            setPatientDOBFieldErrorMessage('Please enter MM / DD / YYYY');
            setPatientDOBFieldError(true);
          }
        }}
      />

      <Box
        style={{
          marginLeft: '14px',
        }}
      >
        <Text
          fontFamily="GT Walsheim"
          fontSize="14px"
          fontWeight="400"
        >
          MM / DD / YYYY
        </Text>
      </Box>
      <CTAButton
        text="Check Coverage"
        handleClick={checkCoverage}
        isDisabled={formValidated === false || loading}
        showLoading={loading}
      />
      <DataSharingInfo />

      {process.env.NODE_ENV !== 'production' && (
        <>
          <OverrideToggle
            userMessage="Override Eligibility Check"
            override={setOverrideEligibilityCheck}
          />
          <OverrideToggle
            userMessage="Force Eligibility Check Failure"
            override={setReturnIneligibleMessage}
          />
        </>
      )}
    </>
  );
};
