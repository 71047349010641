import React from 'react';
import { Text } from 'react-limbix-ui';

import Styled from './DataSharingInfo.styles';

export const DataSharingInfo: React.FC = () => (
  <Text
    color="#363334"
    fontSize="14px"
    fontWeight="400"
    marginTop="24px"
  >
    {'Your privacy is important to us. Read more in our '}
    <Styled.AnchorTag target="_blank" href="https://www.bighealth.com/spark-direct-privacy-policy/">
      Privacy Policy
    </Styled.AnchorTag>
    .
  </Text>
);
