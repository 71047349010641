import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Text } from 'react-limbix-ui';
import { generate } from 'short-uuid';

import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { OnboardingFlowContext, OnboardingFlowProvider } from './OnboardingFlowContext';
import Styled from './OnboardingPage.styles';
import { OnboardingParams } from './types';

const OnboardingPage: React.FC = () => {
  const { slug } = useParams<OnboardingParams>();
  const {
    currentStepIndex,
    currentStep,
    steps,
    setSlug,
    setUid,
  } = useContext(OnboardingFlowContext);

  useEffect(() => {
    setSlug(slug);
    setUid(generate());
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return false;
  };

  return (
    <Styled.PageContainer>
      {steps.length === 0 ? (
        <Text
          marginTop="100px"
          fontSize="48px"
        >
          {/* TODO: This isn't the best error message.
          Talk to product and design about this
          error message when the slug isn't found */}
          {'It looks like we don\'t have an eligibility flow for this org yet... check your url'}
        </Text>
      ) : (
        <>
          <Header
            currentStepIndex={currentStepIndex}
            currentStep={currentStep}
            steps={steps}
          />
          <Styled.PageContentContainer>
            <Styled.StepContainer>
              <form onSubmit={(e) => handleSubmit(e)}>
                {currentStep.element}
              </form>
            </Styled.StepContainer>
          </Styled.PageContentContainer>
        </>
      )}
      <Footer />
    </Styled.PageContainer>
  );
};

// There wasn't a good place to add this context so it's add here, as a wrapper around the OnboardingPage
export const OnboardingPageWithProvider: React.FC = () => (
  <OnboardingFlowProvider>
    <OnboardingPage />
  </OnboardingFlowProvider>
);
