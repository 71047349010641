import styled from 'styled-components/macro';
import { Box } from 'react-limbix-ui';

const PageContainer = styled(Box)`
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 100vh;
    justify-content: flex-start;
`;

const PageContentContainer = styled(Box)`
    display: flex;
    flex-direction: column;
`;

const StepContainer = styled(Box)`
    max-width: 650px;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 60px;
    @media only screen and (max-width: 600px) {
        margin-top: 32px;
  }
`;

export default {
  PageContainer,
  PageContentContainer,
  StepContainer,
};
