import React, { useContext, useState } from 'react';
import { Text } from 'react-limbix-ui';
import { TextField } from '@material-ui/core';

import { useMutation } from '@apollo/client';

import Styled from './PatientInfo.styles';

import { CTAButton } from '../../components/CTAButton/CTAButton';
import { Titles } from '../../components/Titles/Titles';
import { DataSharingInfo } from '../../components/DataSharingInfo/DataSharingInfo';
import { stepContent } from './stepContents';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';
import { WhyDoWeNeedThisInfo } from '../../components/OnboardingPopover/popoverContent/WhyDoWeNeedThisInfo';
import {
  TermsAndConditionsAcknowledgements,
} from '../../components/TermsAndConditionsAcknowledgements/TermsAndConditionsAcknowledgements';
import validatePatientInfoForm from './validatePatientInfoForm';
import { StatePicker } from '../../components/StatePicker/StatePicker';

import { validateEmail, validatePhoneNumber } from '@/utils/stringValidators';

import { convertDOBToDateString } from '@/utils/stringUtils';
import { SPARKRX_PDT_UID } from '@/utils/constants';
import { ENROLL_PATIENT } from '@/apollo/mutations';

export const PatientInfo: React.FC = () => {
  const {
    next,
    updateFormData,
    onboardingFormData,
    uid,
  } = useContext(OnboardingFlowContext);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const formValid = validatePatientInfoForm(onboardingFormData);
  const {
    title,
    subtitle,
    formTitle,
    emailPlaceholder,
    phoneNumberPlaceholder,
    streetAddressPlaceholder,
    cityPlaceholder,
  } = stepContent(onboardingFormData.personEnrolling);

  // validate each text field individually. used for error states and helper text...
  const [patientEmailFieldError, setPatientEmailFieldError] = useState(false);
  const [patientPhoneNumberFieldError, setPatientPhoneNumberFieldError] = useState(false);
  const [patientAddressFieldError, setPatientAddressFieldError] = useState(false);
  const [patientCityFieldError, setPatientCityFieldError] = useState(false);

  const [enrollPatient, { loading }] = useMutation(ENROLL_PATIENT);

  const handlePatientInfo = async () => {
    // if this value is caregiver we need to move on to the next part of the flow before we enroll patient
    if (onboardingFormData.personEnrolling === 'caregiver') {
      next();
      return;
    }
    try {
      const currentISODate = new Date().toISOString();
      const result = await enrollPatient({
        variables: {
          address: onboardingFormData.patientStreetAddress,
          city: onboardingFormData.patientCity,
          dateOfBirth: convertDOBToDateString(onboardingFormData.patientDateOfBirth),
          email: onboardingFormData.patientEmail,
          emergencyContactEmail: onboardingFormData.caregiverEmail,
          emergencyContactName: onboardingFormData.caregiverFirstName,
          emergencyContactPhone: onboardingFormData.caregiverPhoneNumber,
          firstName: onboardingFormData.patientFirstName,
          lastName: onboardingFormData.patientLastName,
          organizationSlug: onboardingFormData.slug,
          pdtUid: SPARKRX_PDT_UID,
          phone: onboardingFormData.patientPhoneNumber,
          postalCode: onboardingFormData.patientZipCode,
          state: onboardingFormData.patientState,
          uid,
          safetyAcknowledgedAt: onboardingFormData.safetyAcknowledgement && currentISODate,
          termsAndPrivacyAcknowledgedAt: onboardingFormData.policyAndTermsAcknowledgement && currentISODate,
        },
      });

      if (result?.data?.enrollDirectAccessPatient?.success === true) {
        // creating the user in our system was successful
        next();
      } else if (result?.data?.enrollDirectAccessPatient?.success === false) {
        // creating the user in our system was UNsuccessful
        // TODO: What do we want show the user in this case?
        console.warn('we were not able to successfully create a profile for this user: ', result);
      }
      next();
    } catch (e) {
      // There was a server error
      // TODO: what do we want to do in this case?
      console.log('there was a server error trying to create this user', e);
    }
  };

  return (
    <>
      <Titles
        title={title}
      />
      <Styled.HoverContainer
        onClick={() => setPopoverOpen(!popoverOpen)}
      >
        <Text
          fontSize="18px"
          fontWeight="400"
          underlined
          lineHeight="24.30px"
        >
          {subtitle}
        </Text>
      </Styled.HoverContainer>
      <Text
        color="#363334"
        fontSize="24px"
        fontWeight="700"
      >
        {formTitle}
      </Text>

      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientEmailFieldError}
        helperText={patientEmailFieldError ? 'Please enter a valid email' : ''}
        label={emailPlaceholder}
        id="patientEmail"
        inputProps={{
          placeholder: emailPlaceholder,
        }}
        value={onboardingFormData.patientEmail}
        onChange={(e) => {
          setPatientEmailFieldError(false);
          updateFormData({ patientEmail: e.target.value });
        }}
        onBlur={(e) => {
          setPatientEmailFieldError(!validateEmail(e.target.value));
        }}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientPhoneNumberFieldError}
        helperText={patientPhoneNumberFieldError ? 'Please enter a valid phone number' : ''}
        label={phoneNumberPlaceholder}
        id="patientPhoneNumber"
        inputProps={{
          placeholder: phoneNumberPlaceholder,
        }}
        value={onboardingFormData.patientPhoneNumber}
        onChange={(e) => {
          setPatientPhoneNumberFieldError(false);
          updateFormData({ patientPhoneNumber: e.target.value });
        }}
        onBlur={(e) => {
          setPatientPhoneNumberFieldError(!validatePhoneNumber(e.target.value));
        }}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientAddressFieldError}
        helperText={patientAddressFieldError ? 'Please enter a valid address' : ''}
        label={streetAddressPlaceholder}
        id="patientStreetAddress"
        inputProps={{
          placeholder: streetAddressPlaceholder,
        }}
        value={onboardingFormData.patientStreetAddress}
        onChange={(e) => {
          setPatientAddressFieldError(false);
          updateFormData({ patientStreetAddress: e.target.value });
        }}
        onBlur={(e) => {
          setPatientAddressFieldError(e.target.value === '');
        }}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientCityFieldError}
        helperText={patientCityFieldError ? 'Please enter a valid city' : ''}
        label={cityPlaceholder}
        id="patientCity"
        inputProps={{
          placeholder: cityPlaceholder,
        }}
        value={onboardingFormData.patientCity}
        onChange={(e) => {
          setPatientCityFieldError(false);
          updateFormData({ patientCity: e.target.value });
        }}
        onBlur={(e) => {
          setPatientCityFieldError(e.target.value === '');
        }}
      />

      <StatePicker
        value={onboardingFormData.patientState}
        onChange={(e) => updateFormData({ patientState: e.target.value })}
      />

      <div
        style={{ marginBottom: '32px' }}
      />

      {onboardingFormData.personEnrolling === 'patient' && (
        <TermsAndConditionsAcknowledgements
          safetyAcknowledgement={onboardingFormData.safetyAcknowledgement}
          safetyAcknowledgementLabel={
            `You acknowledge that, if safety becomes a concern, we will 
            reach out to you at the number provided and, 
            in very rare cases, may send emergency services 
            to the address provided.`
          }
          policyAndTermsAcknowledgement={onboardingFormData.policyAndTermsAcknowledgement}
          onSelect={(data) => updateFormData(data)}
        />
      )}

      <CTAButton
        text="Next"
        handleClick={handlePatientInfo}
        isDisabled={formValid === false}
        showLoading={loading}
      />
      <DataSharingInfo />
      <WhyDoWeNeedThisInfo
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        personEnrolling={onboardingFormData.personEnrolling}
      />
    </>
  );
};
