import styled from 'styled-components';

const RadioButtonContainer = styled.div`
    display: flex;
    border-radius: 8px;
    margin-bottom: 12px;
`;

const RadioInput = styled.input`
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 2px solid #067B65;
    border-radius: 4px;
    transform: translateY(2px);
    display: grid;
    place-content: center;
    &:hover {
        cursor: pointer;
    }
    &::before {
        content: '';
        width: 16px;
        height: 16px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 20px 20px #067B65;
    }
    &:checked::before {
        transform: scale(1);
    }
`;

const RadioInputLabel = styled.label`
    font-family: "GT Walsheim";
    margin-left: 8px;
    font-size: 18px;
    font-weight: 400;
`;

const AnchorTag = styled.a`
    font-family: "GT Walsheim";
    color: #363334;
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
`;

export default {
  RadioButtonContainer,
  RadioInput,
  RadioInputLabel,
  AnchorTag,
};
