import React, { useContext } from 'react';

import { Titles } from '../../components/Titles/Titles';
import { CTAButton } from '../../components/CTAButton/CTAButton';
import { ListBoxWithIcons } from '../../components/ListBoxWithIcons/ListBoxWithIcons';
import { stepContent } from './stepContent';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';

import AnswerQuestionsIcon from '@/assets/AnswerQuestionsIcon.svg';
import BenefitsOfSparkIcon from '@/assets/BenefitsOfSparkIcon.svg';
import DownloadTheAppIcon from '@/assets/DownloadTheAppIcon.svg';

export const CoverageConfirmation: React.FC = () => {
  const { next, onboardingFormData } = useContext(OnboardingFlowContext);
  const {
    title,
    subtitle,
    listTitle,
    listItemText1,
    listItemText2,
    listItemText3,
  } = stepContent(onboardingFormData.personEnrolling);
  return (
    <>
      <Titles
        title={title}
        subtitle={subtitle}
      />
      <ListBoxWithIcons
        title={listTitle}
        listItems={[
          {
            icon: AnswerQuestionsIcon,
            listText: listItemText1,
          },
          {
            icon: DownloadTheAppIcon,
            listText: listItemText2,
          },
          {
            icon: BenefitsOfSparkIcon,
            listText: listItemText3,
          },
        ]}
      />
      <CTAButton
        text="Next"
        handleClick={next}
        isDisabled={false}
      />
    </>
  );
};
