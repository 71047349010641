import styled from 'styled-components/macro';
import { Button } from '@material-ui/core';
import { Box } from 'react-limbix-ui';

const MainContainer = styled(Box)`
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #FEFEFE;
  display: flex;
`;

const BackgroundGraphicContainer = styled(Box)`
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  display: flex;
`;

const BackgroundGraphic = styled.img`
  width: 100%;
`;

const FormContainer = styled(Box)`
  position: absolute;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  padding-right: 45px;
  padding-left: 45px;
  padding-top: 52px;
  padding-bottom: 52px;
  max-width: 489px;
  max-height: 425px;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  background-color: #1F2E81;
`;

export default {
  MainContainer,
  BackgroundGraphicContainer,
  BackgroundGraphic,
  FormContainer,
  StyledForm,
  SubmitButton,
};
