import React, { ReactElement } from 'react';

import { CaregiverInfo } from './steps/CaregiverInfo/CaregiverInfo';
import { CheckCoverage } from './steps/CheckCoverage/CheckCoverage';
import { CoverageConfirmation } from './steps/CoverageConfirmation/CoverageConfirmation';
import { PatientInfo } from './steps/PatientInfo/PatientInfo';
import { TextMessageSent } from './steps/TextMessageSent/TextMessageSent';
import { WhoAreYouEnrolling } from './steps/WhoAreYouEnrolling/WhoAreYouEnrolling';

type Flow = {
    element: ReactElement,
    breadCrumb: string,
}[];

export const defaultCaregiverFlow: Flow = [
  {
    element: <WhoAreYouEnrolling />,
    breadCrumb: 'Coverage',
  },
  {
    element: <CheckCoverage />,
    breadCrumb: 'Coverage',
  },
  {
    element: <CoverageConfirmation />,
    breadCrumb: 'Coverage',
  },
  {
    element: <PatientInfo />,
    breadCrumb: 'Dependent\'s info',
  },
  {
    element: <CaregiverInfo />,
    breadCrumb: 'Caregiver\'s info',
  },
  {
    element: <TextMessageSent />,
    breadCrumb: 'Caregiver\'s info',
  },
];

export const defaultPatientFlow: Flow = [
  {
    element: <WhoAreYouEnrolling />,
    breadCrumb: 'Coverage',
  },
  {
    element: <CheckCoverage />,
    breadCrumb: 'Coverage',
  },
  {
    element: <CoverageConfirmation />,
    breadCrumb: 'Coverage',
  },
  {
    element: <PatientInfo />,
    breadCrumb: 'Your info',
  },
  {
    element: <TextMessageSent />,
    breadCrumb: 'Download App',
  },
];
