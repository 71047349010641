import React from 'react';
import { Text } from 'react-limbix-ui';

import Styled from './Footer.styles';

export const Footer: React.FC = () => (
  <Styled.FooterContainer>
    <Text
      color="#6A717D"
      fontSize="14px"
      textAlign="center"
    >
      © 2023 Big Health
    </Text>
    <Text
      color="#6A717D"
      fontSize="14px"
      textAlign="center"
    >
      Spark Direct Onboarding Version
      {` ${process.env.APP_VERSION}`}
    </Text>
  </Styled.FooterContainer>
);
