import { Box } from 'react-limbix-ui';
import styled from 'styled-components';

const Title = styled(Box)`
    color: #363334;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.40px;
    word-wrap: break-word;
    margin-top: 8px;
`;

const Subtitle = styled(Box)`
    color: #363334;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.40px;
    word-wrap: break-word;
    margin-top: 4px;
`;

const SmallTitle = styled(Box)`
    color: #363334;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    word-wrap: break-word;
    margin-top: 4px;
`;

const MediumTitle = styled(Box)`
    color: #363334;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
    margin-top: 4px;
`;

const Gap = styled(Box)`
    height: 32px;
`;

const BoldText = styled(Box)`
    color: #363334;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.30px;
    word-wrap: break-word;
    display: inline-block;
`;

const Text = styled(Box)`
    color: #363334;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.30px;
    word-wrap: break-word;
    margin-top: 4px;
`;

const AnchorTag = styled.a`
    color: #00B894;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    word-wrap: anywhere;
    text-decoration: underline;
`;

const PhoneTag = styled.a`
    color: #00B894;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    word-wrap: break-word;
    text-decoration: none;
`;

const ImageContainer = styled(Box)`
    width: 100%;
    text-align: center;
`;

const LimbotRainyDay = styled.img`
    width: 151px;
`;

export default {
  Title,
  Subtitle,
  Gap,
  BoldText,
  Text,
  AnchorTag,
  ImageContainer,
  LimbotRainyDay,
  SmallTitle,
  MediumTitle,
  PhoneTag,
};
