import { OnboardingFormData } from '../../defaultFormData';

import { validateEmail, validatePhoneNumber } from '@/utils/stringValidators';

const validateCaregiverInfoForm = (
  onboardingFormData: Partial<OnboardingFormData>,
): boolean => onboardingFormData.caregiverFirstName !== ''
        && onboardingFormData.caregiverLastName !== ''
        && validateEmail(onboardingFormData.caregiverEmail)
        && validatePhoneNumber(onboardingFormData.caregiverPhoneNumber)
        && onboardingFormData.safetyAcknowledgement === true
        && onboardingFormData.policyAndTermsAcknowledgement === true;

export default validateCaregiverInfoForm;
