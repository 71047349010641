import React from 'react';
import { Box, Text } from 'react-limbix-ui';

import Styled from './GenericWarning.styles';

import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['GENERIC_WARNING']
  onClose: () => void;
};

const GenericWarning: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload: {
      headerText,
      primaryButtonText,
      secondaryButtonText,
      onClickPrimaryButton,
      onClickSecondaryButton,
      body,
    },
  } = props;

  const handleClickSecondary = () => {
    onClose();
    if (onClickSecondaryButton) {
      onClickSecondaryButton();
    }
  };
  const handleClickPrimary = () => {
    onClose();
    if (onClickPrimaryButton) {
      onClickPrimaryButton();
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Styled.DialogContent>
        <Box margin="16px">
          <Text fontSize="18px" marginBottom="8px" fontWeight="700">
            {headerText}
          </Text>
          {body}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="center"
          padding="12px"
        >
          <Styled.NoButton onClick={handleClickSecondary}>
            <Text fontSize="14px" textAlign="center">
              {secondaryButtonText}
            </Text>
          </Styled.NoButton>
          <Styled.YesButton onClick={handleClickPrimary}>
            <Text fontSize="14px" textAlign="center" color="white">
              {primaryButtonText}
            </Text>
          </Styled.YesButton>
        </Box>
      </Styled.DialogContent>
    </div>
  );
};

export default GenericWarning;
