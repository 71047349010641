import { Slug } from './types';

export type OnboardingFormData = {
  slug: Slug
  personEnrolling: 'patient' | 'caregiver';
  employeeId: string;
  patientFirstName: string;
  patientLastName: string;
  patientZipCode: string;
  patientDateOfBirth: string;
  patientEmail: string;
  patientPhoneNumber: string;
  patientStreetAddress: string;
  patientCity: string;
  patientState: string;
  caregiverFirstName: string;
  caregiverLastName: string;
  caregiverEmail: string;
  caregiverPhoneNumber: string;
  caregiverZipCode: string;
  safetyAcknowledgement: boolean;
  policyAndTermsAcknowledgement: boolean;
}

export const INITIAL_FORM_DATA: OnboardingFormData = {
  slug: undefined,
  personEnrolling: undefined,
  employeeId: '',
  patientFirstName: '',
  patientLastName: '',
  patientZipCode: '',
  patientDateOfBirth: '',
  patientEmail: '',
  patientPhoneNumber: '',
  patientStreetAddress: '',
  patientCity: '',
  patientState: '',
  caregiverFirstName: '',
  caregiverLastName: '',
  caregiverEmail: '',
  caregiverPhoneNumber: '',
  caregiverZipCode: '',
  safetyAcknowledgement: false,
  policyAndTermsAcknowledgement: false,
};
