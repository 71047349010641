import React from 'react';

import Styled from './TermsAndConditionsAcknowledgements.styles';

interface TermsAndConditionsAcknowledgementsProps {
    safetyAcknowledgement: boolean;
    safetyAcknowledgementLabel: string;
    policyAndTermsAcknowledgement: boolean;
    onSelect: (arg1: {safetyAcknowledgement: boolean} | {policyAndTermsAcknowledgement: boolean}) => void;
}

export const TermsAndConditionsAcknowledgements: React.FC<TermsAndConditionsAcknowledgementsProps> = ({
  safetyAcknowledgement,
  safetyAcknowledgementLabel,
  policyAndTermsAcknowledgement,
  onSelect,
}) => (
  <>
    <Styled.RadioButtonContainer>
      <Styled.RadioInput
        type="checkbox"
        checked={safetyAcknowledgement}
        onChange={() => {
          onSelect({ safetyAcknowledgement: !safetyAcknowledgement });
        }}
      />
      <Styled.RadioInputLabel>{safetyAcknowledgementLabel}</Styled.RadioInputLabel>
    </Styled.RadioButtonContainer>
    <Styled.RadioButtonContainer>
      <Styled.RadioInput
        type="checkbox"
        checked={policyAndTermsAcknowledgement}
        onChange={() => {
          onSelect({ policyAndTermsAcknowledgement: !policyAndTermsAcknowledgement });
        }}
      />
      <Styled.RadioInputLabel>
        {'I agree to the '}
        <Styled.AnchorTag
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.bighealth.com/spark-direct-terms-and-conditions/"
        >
          Terms
        </Styled.AnchorTag>
        {', and '}
        <Styled.AnchorTag
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.bighealth.com/spark-direct-privacy-policy/"
        >
          Privacy Policy.
        </Styled.AnchorTag>
      </Styled.RadioInputLabel>
    </Styled.RadioButtonContainer>
  </>
);
