export const stepContent = (personEnrolling: 'patient' | 'caregiver') => {
  if (personEnrolling === 'caregiver') {
    return {
      title: 'Tell us more about your dependent',
      subtitle: 'Why do we need this info?',
      formTitle: "Dependent's Info",
      emailPlaceholder: "Dependent's email",
      phoneNumberPlaceholder: "Dependent's phone number",
      streetAddressPlaceholder: "Dependent's street address",
      cityPlaceholder: "Dependent's city",
    };
  }
  return {
    title: 'Next, tell us more about you',
    subtitle: 'Why do we need this info?',
    formTitle: 'Your Info',
    emailPlaceholder: 'Email',
    phoneNumberPlaceholder: 'Phone number',
    streetAddressPlaceholder: 'Street address',
    cityPlaceholder: 'City',
  };
};
