export const stepContent = (personEnrolling: 'patient' | 'caregiver') => {
  if (personEnrolling === 'caregiver') {
    return {
      title: 'Your dependent is covered!',
      subtitle: 'Thanks for taking the first step in helping your dependent receive care with Spark Direct.',
      listTitle: "What's next:",
      listItemText1: 'You will answer several questions',
      listItemText2: 'Your dependent will download the Spark Direct app',
      listItemText3: 'They can start seeing benefits of Spark Direct',
    };
  }
  return {
    title: "You're covered!",
    subtitle: 'Thanks for taking the first step in your treatment journey with Spark Direct.',
    listTitle: "What's next:",
    listItemText1: 'Answer several questions',
    listItemText2: 'Download the Spark Direct app',
    listItemText3: 'Start seeing the benefits of Spark Direct',
  };
};
