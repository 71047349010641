import React from 'react';

import { Text } from 'react-limbix-ui';
import { useTranslation } from 'react-i18next';

const AppVersion: React.FC = () => {
  const { t } = useTranslation();
  if (!process.env.APP_VERSION) {
    return <></>;
  }

  return (
    <Text color="#5E6574" fontSize="12px" textAlign="center" lineHeight="24px">
      {`${t('version')} ${process.env.APP_VERSION}`}
    </Text>
  );
};

export default AppVersion;
